<template>
	<label class="checkbox">
		<slot />
		<input
			class="checkbox__input"
			type="checkbox"
			:checked="selected"
			:value="value"
			@input="updateInput"
		/>

		<span class="checkbox__selector" />
	</label>
</template>

<script>
	export default {
		props: {
			value: { type: [String, Number, Boolean], required: true },
			selected: { type: Boolean, required: true }
		},
		methods: {
			updateInput () {
				this.$emit('change', this.value);
			}
		}
	};
</script>

<style lang="scss" scoped>

/* Customize the label (the wrapper) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  font-size: 16px;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__selector:after {
      display: block;
    }
  }

  &__selector {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 1px;
    background-color: $c-white-l;
    border: 1px solid $c-light-navy;

    &:after {
      content: "";
      position: absolute;
      display: none;

      width: 8px;
      height: 8px;
      border-radius: 1px;
      background: $c-dark-navy;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

</style>
