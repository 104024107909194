<template>
	<div data-component="course-edit">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="submit"
			v-if="getFormIsVisible"
		>
			<input-field
				name="Title"
				type="text"
				rules="required"
				placeholder="Course title"
				v-model="course.title"
			/>
			<input-field
				name="Description"
				type="text"
				rules="required"
				placeholder="Course description"
				v-model="course.description"
			/>
			<wysiwyg-field
				name="About"
				type="text"
				rules="required"
				v-model="course.introduction"
			/>
			<range-field
				name="Experience level"
				rules="required"
				v-if="loadedForm"
				:optionLabel="['value', 'text']"
				:options="experienceLevelOptions.slice(0,8)"
				:labelMarks="experienceLevelMarks.slice(0,8)"
				v-model="experienceLevelValues"
			/>
			<select-field
				name="Bidding systems"
				:options="biddingSystemTypeOptions"
				:emptyAllowed="false"
				v-model="biddingSystemType"
			/>
			<select-field
				v-if="getShowSpecificBiddingSystems"
				name="Specific bidding systems (Blank for any)"
				:options="biddingSystemOptions"
				v-model="course.biddingSystems"
				multiple
			/>
			<focus-keywords
				:focusVal="course.focus"
				:keywordsVal="course.keywords"
				:formLoaded="loadedForm"
				:entryRoute="entryRoute"
				@onKeyword="updateKeywords"
				@onFocus="updateFocus"
			/>

			<v2-checkbox-item :value="course.live" :selected="!!course.live" @change="course.live = !course.live">
				This is a live course
			</v2-checkbox-item>

			<v2-checkbox-item :value="course.isFree" :selected="!!course.isFree" @change="course.isFree = !course.isFree">
				Is this a free course?
			</v2-checkbox-item>

			<input-field
				v-if="!course.isFree"
				name="Cost"
				type="number"
				:step="0.01"
				rules="required|minValue:0.30|maxValue:99999.99"
				v-model="course.cost"
				placeholder="Course cost"
			/>
			<upload
				fileType="image"
				:helpText="uploadHelpText"
				:form.sync="form"
				:media="course.media"
				@deleteMedia="deleteMedia"
			/>
			<text-note
				v-if="course.approval_note"
				name="Approval note"
				type="text"
				:value="course.approval_note"
			/>

		</validation-observer>
	</div>
</template>

<script>
	import V2CheckboxItem from '@/components/v2/ui/checkbox/V2CheckboxItem.vue';

	import InputField            from '@/components/forms/InputField';
	import SelectField           from '@/components/forms/SelectField';
	import RangeField            from '@/components/forms/RangeField';
	import Upload              from '@/components/uploads/Upload';
	import TextNote              from '@/components/forms/TextNote';
	import FocusKeywords         from '@/components/forms/FocusKeywords';
	import actionClick           from '@/mixins/actionClick';
	import invalidFormMessage    from '@/mixins/invalidFormMessage';
	import routeParams           from '@/mixins/routeParams';
	import api                   from '@/services/api';
	import { experienceLevels }  from '@/consts';
	import { biddingSystems }    from '@/consts';

	export default {
		components: {
			V2CheckboxItem,

			InputField,
			WysiwygField: () => import('@/components/forms/WysiwygField'),
			SelectField,
			FocusKeywords,
			TextNote,
			RangeField,
			Upload
		},
		mixins: [actionClick, invalidFormMessage, routeParams],
		props: {
			entryRoute: {
				type: String,
				default: 'teaching'
			},
			isCreate: {
				type: Boolean,
				default: false
			},
			cancelRoute: {
				type: String,
				default: ''
			},
			courseId: {
				type: String,
				default: ''
			}
		},
		data: () => ({
			form: undefined,
			uploadHelpText: [
				'Images should be 500px x 500px'
			],
			experienceLevelOptions: experienceLevels,
			experienceLevelValues: [1, 8],
			experienceLevelMarks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
			keywords: [],
			loadedKeywords: false,
			loadedForm: false,
			biddingSystemType: 'specific',
			biddingSystemTypeOptions: [
				{
					text: 'Specific bidding systems',
					value: 'specific'
				},
				{
					text: 'None',
					value: 'none'
				}
			],
			biddingSystemOptions: biddingSystems.map((biddingSystem, index) => {
				return {
					text: biddingSystem.value,
					value: biddingSystem.value
				};
			}),
			course: {
				title:               '',
				description:         '',
				introduction:        '',
				minExperienceLevel:  '',
				maxExperienceLevel:  '',
				biddingSystems:      [],
				keywords:            [],
				focus:	             [],
				status:              '',
				approval:            '',
				cost:                null,
				isFree:              false,
				live:                false
			}
		}),
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: this.isCreate ? 'Create' : 'Save',
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							route: this.cancelRoute
						}
					]
				};
			},
			getFormIsVisible () {
				if (this.isCreate) {
					return true;
				}
				return this.course.title !== '';
			},
			getShowSpecificBiddingSystems () {
				return Array.isArray(this.course.biddingSystems);
			},
			getBreadcrumbs () {
				return [
					(this.getContext === 'admin' ?
						{
							path: '/admin',
							text: 'Admin'
						} : false
					),
					(this.getContext === 'teaching' ?
						{
							path: '/teaching',
							text: 'Teaching'
						} : false
					),
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					(!this.isCreate ?
						{
							path: this.getCourseRoute,
							text: this.course?.title?.length ? this.course.title : 'Course'
						} : false
					),
					(!this.isCreate ?
						{
							path: `${this.getCourseRoute}/edit`,
							text: 'Edit'
						} : false
					),
					(this.isCreate ?
						{
							path: `${this.getCoursesRoute}/create`,
							text: 'New course'
						} : false
					)
				].filter(Boolean);
			}
		},
		watch: {
			experienceLevelValues (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.course.minExperienceLevel = newVal[0];
					this.course.maxExperienceLevel = newVal[1];
				}
			},
			'course.biddingSystems' (newVal) {
				if (Array.isArray(newVal)) {
					this.biddingSystemType = 'specific';
					return;
				}
				this.biddingSystemType = 'none';
			},
			biddingSystemType (newVal) {
				if (newVal === 'specific') {
					if (Array.isArray(this.course.biddingSystems)) {
						return false;
					}
					this.course.biddingSystems = [];
					return;
				}
				if (this.course.biddingSystems === null) {
					return false;
				}
				this.course.biddingSystems = null;
			}
		},
		mounted () {
			if (this.isCreate) {
				this.course.status = 'draft';
				this.experienceLevelValues = [1, 1];
				this.loadedKeywords = true;
				this.loadedForm = true;
				this.$store.commit('ui/setLoadingIsHidden');
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				return;
			}
			this.setCourse();
		},
		methods: {

			async createMedia (courseId) {
				const media = await api[this.entryRoute].createMediaByCourseId({
					courseId: courseId,
					media: this.form
				});
				if (!media) {
					return false;
				}
				return media;
			},

			async deleteMedia () {
				if (!this?.course?.media?.[0]?.id) {
					return false;
				}

				this.course.media = [];
			},

			updateFocus (val) {
				this.course.focus = val;
			},
			updateKeywords (val) {
				this.course.keywords = val;
			},
			async setCourse () {
				const course = await api[this.entryRoute].getCourseById({
					courseId: this.courseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}
				if (Number(course.cost) === 0) {
					course.isFree = true;
				}
				this.course = {
					...this.course,
					...course
				};
				this.experienceLevelValues = [this.course.minExperienceLevel, this.course.maxExperienceLevel];
				this.loadedForm = true;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				let course;
				if (this.course.isFree) {
					this.course.cost = 0;
				}
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				if (this.isCreate) {
					course = await api.teaching.createCourse({
						course: this.course
					});

					if (!course.id) {
						return;
					}
				} else {
					course = await api[this.entryRoute].updateCourseById({
						course: this.course,
						courseId: this.courseId
					});
					if (!course) {
						return;
					}
				}

        if (this.form) {
					const media = await this.createMedia(course.id);
					if (!media) {
						this.$router.push(this.getUserRoute);
						this.$store.commit('ui/showNotification', {
							notification: 'The file could not be uploaded. Please edit the course and try again.'
						});
						return;
					}
				}

				await this.$router.push(`/${this.entryRoute}/courses/${course.id}`);
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been ' + (this.isCreate ? 'created' : 'updated')
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
::v-deep [data-component=input-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  input {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);

  }
}

::v-deep [data-component=wysiwyg-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  .quill-editor {
    background-color: $c-white-l;
    font-family: "DM Sans", sans-serif !important;

    p {
      font-family: "DM Sans", sans-serif !important;
    }
  }

  .ql-container.ql-snow {
    border: 1px solid $c-gray-l;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid $c-gray-l;
  }
}

::v-deep [data-component=range-field] > span {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  input {
    padding: 10px 12px !important;
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);
  }

  .vue-slider-process {
    background-color: $c-medium-navy !important;
  }

  .vue-slider:hover .vue-slider-process {
    background-color: $c-darkest-navy !important;
  }

  .vue-slider-dot-handle {
    border: 2px solid $c-medium-navy !important;
  }

  .vue-slider-mark-step-active {
    box-shadow: 0 0 0 2px $c-medium-navy !important;
  }

  .vue-slider-dot-handle-focus {
    box-shadow: 0 0 0 5px rgb(186, 193, 202, 0.2);
  }
}

::v-deep [data-component=select-field] > div {

  label {
    @include font(18px, $c-darkest-navy, 400, 23px);
    margin-bottom: 6px !important;
  }

  .multiselect__tags {
    border-radius: 4px !important;
    border: 1px solid $c-gray-l;
    @include font(16px, $c-darkest-navy, 400, 20px);
  }
}

</style>
